import styled from 'styled-components';
import {Commonh6,Commonh1} from '../Common/common.style';
import {device} from '../Common/device';
import BackgroundImg from '../../../assets/realestate-4-images/banner-pattern.jpg';

export const BannerSection = styled.section`
    min-height:100vh;
`;

export const BannerWrapper = styled.div`
    background-image:url(${BackgroundImg});

    .slick-list{
        line-height:0;
    }

    .slick-active{
        z-index:999;
    }

    .slick-dots{
        height:100%;
        display:flex;
        align-items:center;
        bottom:0;
        width:auto;
        position:absolute;
        right:0;
        margin:0px;
        padding-right:15px;

        @media ${device.laptopL} {
            margin-right: calc(50% - 570px);
        }
     
        @media ${device.laptopM} {
            margin-right: calc(50% - 480px);
        }
     
        @media ${device.laptop} {
            margin-right: calc(50% - 360px);
        }

        @media ${device.tablet} {
            height:auto;
            display:block;
            left:0;
            right:0;
            margin-right:0;
            padding-right:0px;
        }
    }

    ul{
        margin:0px;
    }

    li{
        display:block !important;
        margin:5px 0px;

        @media ${device.tablet} {
            display:inline-block !important;
            margin:5px 5px;
        }
    }

    .slick-dots span{
        width:15px;
        height:15px;
        border-radius:100%;
        border:3px solid #fff;
        display:block;
    }
    
    .slick-active span{
        background:#fff !important;
        width:15px !important;
        height:15px !important;
    }
`;

export const BannerSlide = styled.div`
    position:relative;
`;

export const BannerImageHolder = styled.div`
    position:relative;
    width:65%;
    min-height:100vh;
    margin-left: auto;

    @media ${device.laptop} {
        width:55%;
    }
    @media ${device.tablet} {
        width:100%;
    }

    .bannerImage{
        height:100vh;
    }

`;

export const BannerContents = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:100%;
    display:flex;
    align-items:center;
`;

export const BannerContentLeft = styled.div`
    max-width:50%;
    text-align:left;

    @media ${device.laptopM} {
        max-width:65%;
    }

    @media ${device.tablet} {
        max-width:100%;
        padding: 0px 10px;
    }
`;

export const BannerHeading = styled(Commonh1)`
    color:#fff;
    text-align:left;

    @media ${device.tablet} {
        text-align:center;
    }
`;

export const BannerSubHeading = styled(Commonh6)`
    color:#fff;
    text-align:left;

    @media ${device.tablet} {
        text-align:center;
    }
`;

export const NextPrev = styled.div`
    text-align:center;
    position:absolute;
    bottom:40px;
    right:50px;
`;

export const ImgButton = styled.button`
    line-height:0px;
    padding-bottom:0px;
    border:none;
    background:none;
    outline:none;
`;

export const PrevImg = styled.img`
    margin:0px 5px;
    cursor:pointer;
    height:40px;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }

    @media ${device.tablet} {
        height:20px;
    }
`;

export const NextImg = styled.img`
    margin:0px 5px;
    cursor:pointer;
    transition:all .4s;
    height:40px;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
    @media ${device.tablet} {
        height:20px;
    }   
`;