import {createGlobalStyle} from 'styled-components';
const GlobalStyle = createGlobalStyle`


    body{
        font-family: 'Muli', sans-serif;
        font-weight: 400;

        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5{
        font-family: 'Oswald', sans-serif;
        font-weight: 700;
    }
    
    h6,p{
        font-family: 'Muli', sans-serif;
        font-weight: 400;
    }
    a{
        font-size:16px;
        font-family: 'Muli', sans-serif;
        font-weight: 400;
    }
    img{
        margin-bottom:0px;
    }
`;

export default GlobalStyle;