import React from 'react';
import {FeaturesSection,BackgroundImage,SectionWrapper,HeadingLayout,Heading,FeaturesRow,
    FeaturesCardWrapper,FeaturesCardImgHolder,FeaturesCardIcon,
    FeaturesCardHeading,FeaturesCardPara,FeaturesCard
} from './features.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useStaticQuery, graphql } from 'gatsby';

const Features = () => {  
    const JSONData  = useStaticQuery(graphql`
        query{
            realestatePage4Json {
                Features {
                    Heading
                    FeaturesRight{
                        FeaturesCardIcon
                        FeaturesCardHeading
                        FeaturesCardPara
                    }
                }
            }
        }
    `);
    const FeaturesData = JSONData.realestatePage4Json.Features;
    return(
        <FeaturesSection id="featuresSection">
        
            <BackgroundImage src="../realestate-4-images/features-banner.jpg" alt=""/>
            <HeadingLayout>
                <Heading>
                {FeaturesData.Heading}
                </Heading>
            </HeadingLayout>

            <SectionWrapper>
                <Container>
                    <FeaturesRow>
                    { 
                        FeaturesData.FeaturesRight.map((item,idx) => {
                        return <Col lg="4" md="12">
                                    <FeaturesCardWrapper position={idx+1}>
                                        <FeaturesCard>
                                            <FeaturesCardImgHolder>
                                                <FeaturesCardIcon src={item.FeaturesCardIcon} alt=""/>
                                            </FeaturesCardImgHolder>
                                            <FeaturesCardHeading>{item.FeaturesCardHeading}</FeaturesCardHeading>
                                            <FeaturesCardPara>{item.FeaturesCardPara}</FeaturesCardPara>
                                        </FeaturesCard>
                                    </FeaturesCardWrapper>
                            </Col>
                        })
                    }
                    </FeaturesRow>
                </Container>
            </SectionWrapper>
        </FeaturesSection>
    )
}
export default Features;