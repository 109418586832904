import React,{Component} from 'react';
import {GallerySection,Heading,GalleryInnerContainer,GalleryInnerContainerBig
} from './gallery.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

class Gallery extends Component {
   
    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }

    render() {
        return (
            <GallerySection id="gallerySection">
                <Container>
                    <Heading>
                        {this.props.GalleryData.Heading}
                    </Heading>
                    <Row>
                        <Col md={4}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,0)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[0].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryInnerContainer>
                        </Col>
                        <Col md={4}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,1)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[1].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryInnerContainer>
                        </Col>
                        <Col md={4}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,2)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[2].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryInnerContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,3)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[3].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryInnerContainer>
                            <Row>
                                <Col md={6}>
                                    <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,4)}>
                                        <GatsImg 
                                            fluid={this.props.GalleryData.Slider[4].GallerySliderImg.childImageSharp.fluid} 
                                            className="GallerySliderImg"
                                            alt=""
                                        />
                                    </GalleryInnerContainer>
                                </Col>
                                <Col md={6}>
                                    <GalleryInnerContainer onClick={this.openDialogBox.bind(this,this.props.GalleryData,5)}>
                                        <GatsImg 
                                            fluid={this.props.GalleryData.Slider[5].GallerySliderImg.childImageSharp.fluid} 
                                            className="GallerySliderImg"
                                            alt=""
                                        />
                                    </GalleryInnerContainer>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <GalleryInnerContainerBig onClick={this.openDialogBox.bind(this,this.props.GalleryData,6)}>
                                <GatsImg 
                                    fluid={this.props.GalleryData.Slider[6].GallerySliderImg.childImageSharp.fluid} 
                                    className="GallerySliderImg"
                                    alt=""
                                />
                            </GalleryInnerContainerBig>
                        </Col>

                    </Row>
                </Container>
            </GallerySection>
        );
    }
}

export default props => (
<StaticQuery
    query={graphql`
        query {
            realestatePage4Json{
                Gallery{
                    Heading
                    Slider{
                        GallerySliderImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
    render={(data) => (
        <Gallery 
        GalleryData={data.realestatePage4Json.Gallery}
        {...props}
        />
    )}
/>
)
