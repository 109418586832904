import styled from 'styled-components';
import {SectionHeading,Commonh5} from '../Common/common.style';
import {device} from '../Common/device';
import BackgroundImg from '../../../assets/realestate-4-images/amenties-parallax-banner.jpg';

export const AmenitiesSection = styled.section`
    background-image:url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
    padding:100px 0px 50px;

    @media ${device.tablet} {
        padding:80px 10px 30px;
    }
`;

export const Heading = styled(SectionHeading)`
    color:#fff;
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }
`;

export const AmenitiesLayout = styled.div`
    text-align:center;
    margin-bottom:50px;
`;

export const AmenitiesImage = styled.img`
    height:50px;
    margin-bottom:20px;
`;

export const AmenitiesText = styled(Commonh5)`
    text-transform:uppercase;
    color:#fff;
    line-height:1;
`;


