import styled from 'styled-components';
import {SectionHeading,Commonh5,Commonpara} from '../Common/common.style';
import {device} from '../Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const TeamSection = styled.section`
    padding:100px 0px 80px;
    
    @media ${device.tablet} {
        padding:80px 0px 60px;
    }
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:50px;

    @media ${device.tablet} {
        margin-bottom:35px;
    }
`;

export const SliderOuterWrapper = styled.div`
    margin-top:25px;
    margin-left:-15px;
    margin-right:-15px;
`;

export const TeamSliderWrapper = styled.div`
    outline:0;
`;

export const TeamSliderLayout = styled.div`
    margin: 20px 15px;
    position:relative;

    @media ${device.tablet} {
        margin: 20px 10px;
    }
`;
export const ImageLayout = styled.div`
    text-align: center;
    justify-content: center;
    display: flex;
`;
export const TeamImgFigure = styled.figure`
    overflow: hidden;
    margin-bottom:0px;
    position:relative;
    width:100%;

    :hover .teamImg{
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    .teamImg{
        height:250px;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
    }
`;

export const TeamDescWrapper = styled.div`
    box-shadow: 0px 0px 15px 0px #ddd;
    margin-top: -30px;
    margin-right: 10px;
    margin-left: 10px;
`;

export const TeamDesc = styled.div`
    text-align:center;
    background:#fff;
    position:relative;
    padding:15px 10px;
`;

export const TeamName = styled(Commonh5)`
    margin-bottom:10px;
    line-height:1;
`;

export const TeamPhoneAnchor = styled.a`
    :hover{
        color:#ef5350;
    }
`; 

export const TeamPhone = styled(Commonpara)`
    text-align: center;
    color:#ef5350;
    margin-bottom:0px;
    line-height:1;
`;

export const SocialLayout = styled.div`
    display:flex;
    align-items:center;
    position:absolute;
    top:180px;
    background:rgba(0,0,0,0.3);
    transition:0.3s all;
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    transition:all .5s;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    transition:all .5s;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    transition:all .5s;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #fff;
    padding: 6px;
    border-radius: 100%;
    margin: 0px 8px;
    transition:all .5s;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const NextPrev = styled.div`
    text-align:center;
`;

export const ImgButton = styled.button`
    border:none;
    background:none;
    outline:0;

    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;


