import styled from 'styled-components';
import {SectionHeading,Commonpara,Commonh5} from '../Common/common.style';
import {device} from '../Common/device';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const FeaturesSection = styled.section`
    position:relative;
`;

export const BackgroundImage = styled.img`
    height:295px;
    width:100%;
    position:relative;
    z-index:9;
    object-fit:cover;

    @media ${device.tablet}{
        height:260px;
    }
`;

export const SectionWrapper = styled.div`
    padding:0px 0px 70px;
    

    @media ${device.tablet} {
        padding:0px 10px 50px;
    }
`;

export const HeadingLayout = styled.div`
    position:absolute;
    top: 100px;
    z-index: 99;
    left: 0;
    right: 0;

    @media ${device.tablet} {
        top: 80px;
    }
`;

export const Heading = styled(SectionHeading)`
    color:#fff;
    margin-bottom:75px;
    padding:0px 25px;
`;

export const FeaturesRow = styled(Row)`
    margin-left:-35px; //15px margin in Col, 20px margin in FeaturesCardWrapper
    margin-right:-35px; 
    margin-top:-80px;

    @media ${device.laptop} {
        margin-left:-15px;
        margin-right:-15px;
    }
`;

export const FeaturesCardWrapper = styled.div`
    background:#fff;
    cursor:pointer;
    margin:0px 20px 30px;
    box-shadow: 0px 0px 15px 0px #ddd;

    @media ${device.laptop} {
        margin:0px 0px 30px;
    }
`;

export const FeaturesCard = styled.div`
    background:#fff;
    cursor:pointer;
    padding: 50px 30px;
    position:relative;
    z-index:10;
`;

export const FeaturesCardImgHolder = styled.div`
    margin-bottom:20px;
    text-align:center;
`;

export const FeaturesCardIcon = styled.img`
    margin-bottom:0px;
    height: 80px;
`;

export const FeaturesCardHeading = styled(Commonh5)`
    text-transform:uppercase;
`;

export const FeaturesCardPara = styled(Commonpara)`
    margin-bottom:0px;
    text-align:center;
`;